import TGGLoader from "@components/Common/TGGLoader";
import { useAuth } from "@context/auth";
import AuthServices from "@services/AuthServices";
import { routes } from "@utils/route";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { Fragment, useEffect } from "react";

const Home = () => {
  const route = useRouter();
  const { authenticateAndRedirect } = useAuth();

  useEffect(() => {
    try {
      const resp = AuthServices.handleAuthentication();
      if (resp === "Authenticated") {
        authenticateAndRedirect?.(true);
        return;
      }
    } catch (error) {
      console.error(error);
    } finally {
      route.push(routes.common.login);
    }
  }, []);

  return (
    <Fragment>
      <Head>
        <title>{"Welcome to HUB | TGG Accounting"}</title>
      </Head>
      <div className="flex h-screen w-full items-center justify-center">
        <TGGLoader />
      </div>
    </Fragment>
  );
};

export default Home;
