import Image from "next/image";
import React from "react";

const TGGLoader = () => {
  return (
    <Image
      alt="loading"
      src="/assets/gif/loading_animation.gif"
      width={80}
      height={80}
      className="w-20 h-20"
      style={{
        objectFit: "cover",
      }}
    />
  );
};

export default TGGLoader;
